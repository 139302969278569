var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-form",
    { attrs: { readonly: _vm.value.id > 0 && !_vm.canEditSetupTables } },
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "px-0", attrs: { cols: "12", md: "12" } },
                [
                  _c(
                    "v-btn",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.cancel(false)
                        },
                      },
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _vm.canEditSetupTables
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "float-right",
                          attrs: { color: "green", dark: "" },
                          on: { click: _vm.onSubmit },
                        },
                        [_vm._v(" save ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-expansion-panels",
        { attrs: { accordion: "", mandatory: "" } },
        [
          _c(
            "v-expansion-panel",
            [
              _c("v-expansion-panel-header", [_vm._v("General")]),
              _c(
                "v-expansion-panel-content",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "10" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              value: _vm.value.name,
                              label: "Name",
                              required: "",
                              "error-messages": _vm.handleErrors(
                                _vm.$v.form.name
                              ),
                            },
                            on: {
                              blur: function ($event) {
                                return _vm.$v.form.name.$touch()
                              },
                            },
                            model: {
                              value: _vm.$v.form.name.$model,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.$v.form.name,
                                  "$model",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "$v.form.name.$model",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "5" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              value: _vm.value.contactName,
                              label: "Contact Name",
                              required: "",
                              readonly: _vm.value.readonly,
                            },
                            on: {
                              blur: function ($event) {
                                return _vm.$v.form.contactName.$touch()
                              },
                            },
                            model: {
                              value: _vm.$v.form.contactName.$model,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.$v.form.contactName,
                                  "$model",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "$v.form.contactName.$model",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "5" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              value: _vm.value.email,
                              label: "Email",
                              required: "",
                              readonly: _vm.value.readonly,
                            },
                            on: {
                              blur: function ($event) {
                                return _vm.$v.form.email.$touch()
                              },
                            },
                            model: {
                              value: _vm.$v.form.email.$model,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.$v.form.email,
                                  "$model",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "$v.form.email.$model",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "5" } },
                        [
                          _c("phone-number", {
                            attrs: {
                              value: _vm.value.phone,
                              label: "Phone",
                              required: "",
                              readonly: _vm.value.readonly,
                            },
                            on: {
                              blur: function ($event) {
                                return _vm.$v.form.phone.$touch()
                              },
                            },
                            model: {
                              value: _vm.$v.form.phone.$model,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.$v.form.phone,
                                  "$model",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "$v.form.phone.$model",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-expansion-panel",
            [
              _c("v-expansion-panel-header", [_vm._v("Address")]),
              _c(
                "v-expansion-panel-content",
                [
                  _c(
                    "v-row",
                    [
                      _c("address-form", {
                        ref: "aform",
                        attrs: {
                          value: _vm.address,
                          showLatLng: false,
                          required: false,
                        },
                        on: { input: _vm.handleInput },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }