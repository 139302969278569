<template>
  <setup-table
    :title="`Approved Charter`"
    :items="items"
    :headers="headers"
    :loading="loading"
    :clearItem="!showForm || !editedItem.id"
    @createItem="createItem()"
    @editItem="editItem"
    @deleteItems="deleteItems"
  >
    <template #itemForm v-if="showForm">
      <approved-charter-form
        ref="approvedCharterForm"
        :value="editedItem"
        @submit="saveItem"
        @closeForm="closeForm"
        @formChanges="formChanges"
      ></approved-charter-form>
    </template>
  </setup-table>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
  GET_ADDITIONAL_TRANSPORTATIONS,
  SAVE_ADDITIONAL_TRANSPORTATION,
  DELETE_ADDITIONAL_TRANSPORTATIONS,
} from '@/store/modules/AdditionalTransportation/actions';
import SetupTable from './SetupTable.vue';
import ApprovedCharterForm from './ApprovedCharterForm.vue';
import promptBeforeLeave from '@/mixins/promptBeforeLeave';
import { cloneDeep } from 'lodash';

export default {
  name: 'AdditionalTransportationSetupTable',
  inject: ['eventHub'],
  components: { SetupTable, ApprovedCharterForm },
  data() {
    return {
      showForm: false,
      items: [],
      editedItem: {},
      loading: true,
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Email', value: 'email' },
        { text: 'Phone', value: 'phone' },
        { text: 'Contact', value: 'contactName' },
      ],
    };
  },
  computed: {
    ...mapGetters('additionalTransportation', ['additionalTransportations']),
  },
  created() {
    this.fetchItems();
  },
  methods: {
    ...mapActions('additionalTransportation', [
      GET_ADDITIONAL_TRANSPORTATIONS,
      SAVE_ADDITIONAL_TRANSPORTATION,
      DELETE_ADDITIONAL_TRANSPORTATIONS,
    ]),
    async fetchItems() {
      await this.getAdditionalTransportations();
      this.items = this.additionalTransportations.filter((e) => e.type == 'charter');
      this.loading = false;
    },
    async createItem() {
      const yes = await this.performPromptBeforeLeave();

      if (!yes) return;
      this.editedItem = { id: 0, name: '' };
      this.baseFormValue = {
        id: 0,
        type: 'charter',
        name: '',
        email: '',
        phone: '',
        contactName: '',
        address: {
          name: '',
          isPrimary: false,
          address: '',
          address2: '',
          city: '',
          state: '',
          zip: null,
          lng: null,
          lat: null,
        },
        active: true,
      };
      this.latestFormValue = cloneDeep(this.baseFormValue);
      this.showForm = true;
    },
    async editItem(item) {
      const yes = await this.performPromptBeforeLeave();

      if (!yes) return;
      this.editedItem = { ...item };
      this.baseFormValue = cloneDeep(this.editedItem);
      this.latestFormValue = cloneDeep(this.editedItem);
      this.showForm = true;
    },
    async saveItem(item) {
      try {
        const r = await this.saveAdditionalTransportation(item);
        if (r && r.id) {
          this.$myalert.success('Approved Charter saved');
          await this.fetchItems();
          this.editedItem = { ...item, id: r.id };
          this.baseFormValue = cloneDeep(this.editedItem);
        }
      } catch (error) {
        this.$myalert.error(error.message);
      }
    },
    async deleteItems(items) {
      const yes = await this.$myconfirm(
        `Are you sure you want to delete ${
          items.length > 1 ? 'these Approved Charters' : 'this Approved Charter'
        }? This operation cannot be undone.`
      );
      if (yes) {
        try {
          const r = await this.deleteAdditionalTransportations(items.map((e) => e.id));
          if (r.done) {
            this.$myalert.success(`${items.length > 1 ? 'Approved Charters' : 'Approved Charter'} deleted`);
            this.showForm = false;
            this.fetchItems();
          }
        } catch (error) {
          this.$myalert.error(error.message);
        }
      }
    },
    closeForm() {
      this.showForm = false;
      this.baseFormValue = null;
      this.latestFormValue = null;
    },
    formChanges(value) {
      this.latestFormValue = cloneDeep(value);
    },
  },
  mixins: [promptBeforeLeave],
};
</script>

<style lang="scss">
.table-action {
  margin-left: 10px;
}
</style>
